import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
export type IsSharpSetupCompleteFragment = { __typename?: 'OrgSharpConfig', hrEmail?: string | null, sharpPolicy?: { __typename?: 'Media', id: number, mediaUrls: { __typename?: 'TranslationSet', en: string } } | null };

export const IsSharpSetupCompleteFragmentDoc = gql`
    fragment IsSharpSetupComplete on OrgSharpConfig {
  sharpPolicy {
    id
    mediaUrls {
      en
    }
  }
  hrEmail
}
    `;